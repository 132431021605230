// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-projects-all-games-js": () => import("./../../../src/pages/projects/all-games.js" /* webpackChunkName: "component---src-pages-projects-all-games-js" */),
  "component---src-pages-projects-anemone-js": () => import("./../../../src/pages/projects/anemone.js" /* webpackChunkName: "component---src-pages-projects-anemone-js" */),
  "component---src-pages-projects-cavalcade-js": () => import("./../../../src/pages/projects/cavalcade.js" /* webpackChunkName: "component---src-pages-projects-cavalcade-js" */),
  "component---src-pages-projects-g-pet-js": () => import("./../../../src/pages/projects/g-pet.js" /* webpackChunkName: "component---src-pages-projects-g-pet-js" */),
  "component---src-pages-projects-giants-chalice-js": () => import("./../../../src/pages/projects/giants-chalice.js" /* webpackChunkName: "component---src-pages-projects-giants-chalice-js" */),
  "component---src-pages-projects-nasa-pogs-js": () => import("./../../../src/pages/projects/nasa-pogs.js" /* webpackChunkName: "component---src-pages-projects-nasa-pogs-js" */),
  "component---src-pages-projects-petfinderx-js": () => import("./../../../src/pages/projects/petfinderx.js" /* webpackChunkName: "component---src-pages-projects-petfinderx-js" */),
  "component---src-pages-projects-s-pet-js": () => import("./../../../src/pages/projects/s-pet.js" /* webpackChunkName: "component---src-pages-projects-s-pet-js" */),
  "component---src-pages-projects-soundtracks-js": () => import("./../../../src/pages/projects/soundtracks.js" /* webpackChunkName: "component---src-pages-projects-soundtracks-js" */),
  "component---src-pages-projects-supreme-defender-js": () => import("./../../../src/pages/projects/supreme-defender.js" /* webpackChunkName: "component---src-pages-projects-supreme-defender-js" */),
  "component---src-pages-projects-trade-around-js": () => import("./../../../src/pages/projects/trade-around.js" /* webpackChunkName: "component---src-pages-projects-trade-around-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

